@font-face {
  font-family: 'Streamster';
  src: url('assets/hinted-Streamster.eot');
  src: local('Streamster'),
      url('assets/hinted-Streamster.eot?#iefix') format('embedded-opentype'),
      url('assets/hinted-Streamster.woff2') format('woff2'),
      url('assets/hinted-Streamster.woff') format('woff'),
      url('assets/hinted-Streamster.ttf') format('truetype'),
      url('assets/hinted-Streamster.svg#Streamster') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Streamster', fantasy, sans-serif;
  background-color: rgb(3, 3, 3) !important;
}

main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

h1 {
  font-size: 8vh;
  color: #f00000;
  text-shadow: 0.1vw 0.4vw rgba(255, 251, 150, 0.9), -1.5vw 0.1vw 0 rgba(182, 1, 254, 0.5);
  margin-bottom: 25vh;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper {
  height: 80%;
  width: 100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
